// Generated by Framer (ddd30d5)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useOnVariantChange, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["grTvWsSDA", "Jywg6prkS", "bjFnwewgB", "emPCwEEVo", "iF7mVgbOF"];

const serializationHash = "framer-ZrXO0"

const variantClassNames = {bjFnwewgB: "framer-v-1wovle1", emPCwEEVo: "framer-v-4pxkwv", grTvWsSDA: "framer-v-p5le3l", iF7mVgbOF: "framer-v-88p0dk", Jywg6prkS: "framer-v-mjqnap"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const radiusForCorner = (value, cornerIndex) => {
    if (typeof value === "number" && Number.isFinite(value)) return Math.max(0, value) + "px";
    if (typeof value !== "string" || typeof cornerIndex !== "number") return undefined;
    const segments = value.split(" ");
    return segments[cornerIndex] || segments[cornerIndex - 2] || segments[0];
};


const transition1 = {delay: 0, duration: 0.45, ease: [0, 0, 1, 1], type: "tween"}

const transition2 = {delay: 0, duration: 1, ease: [0, 0, 1, 1], type: "tween"}

const transition3 = {delay: 0, duration: 0.35, ease: [0, 0, 1, 1], type: "tween"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const humanReadableVariantMap = {"Variant 1": "grTvWsSDA", "Variant 2": "Jywg6prkS", "Variant 3": "bjFnwewgB", "Variant 4": "emPCwEEVo", "Variant 5": "iF7mVgbOF"}

const getProps = ({background, height, id, radius, width, ...props}) => { return {...props, COj8n3hCj: background ?? props.COj8n3hCj ?? "var(--token-01526063-22e0-41e6-9449-953f948a186a, rgb(19, 19, 21))", tGcqj6wny: radius ?? props.tGcqj6wny ?? 16, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "grTvWsSDA"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;radius?: number;background?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, tGcqj6wny, COj8n3hCj, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "grTvWsSDA", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onAppear1lgwy0f = activeVariantCallback(async (...args) => {
await delay(() => setVariant("Jywg6prkS"), 1)
})

const onAppear98hr34 = activeVariantCallback(async (...args) => {
await delay(() => setVariant("bjFnwewgB"), 1000)
})

const onAppearxd09h9 = activeVariantCallback(async (...args) => {
await delay(() => setVariant("emPCwEEVo"), 350)
})

const onAppear1rzhqxh = activeVariantCallback(async (...args) => {
await delay(() => setVariant("iF7mVgbOF"), 1000)
})

const onAppeari1oqve = activeVariantCallback(async (...args) => {
await delay(() => setVariant("Jywg6prkS"), 350)
})

useOnVariantChange(baseVariant, {bjFnwewgB: onAppearxd09h9, default: onAppear1lgwy0f, emPCwEEVo: onAppear1rzhqxh, iF7mVgbOF: onAppeari1oqve, Jywg6prkS: onAppear98hr34})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1} {...addPropertyOverrides({bjFnwewgB: {value: transition3}, emPCwEEVo: {value: transition2}, Jywg6prkS: {value: transition2}}, baseVariant, gestureVariant)}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-p5le3l", className, classNames)} data-framer-name={"Variant 1"} data-highlight layoutDependency={layoutDependency} layoutId={"grTvWsSDA"} ref={ref ?? ref1} style={{backgroundColor: "var(--token-8440ea11-aa1f-45b8-8841-ac0e829a648f, rgb(28, 30, 32))", borderBottomLeftRadius: radiusForCorner(tGcqj6wny, 3), borderBottomRightRadius: radiusForCorner(tGcqj6wny, 2), borderTopLeftRadius: radiusForCorner(tGcqj6wny, 0), borderTopRightRadius: radiusForCorner(tGcqj6wny, 1), ...style}} {...addPropertyOverrides({bjFnwewgB: {"data-framer-name": "Variant 3"}, emPCwEEVo: {"data-framer-name": "Variant 4"}, iF7mVgbOF: {"data-framer-name": "Variant 5"}, Jywg6prkS: {"data-framer-name": "Variant 2"}}, baseVariant, gestureVariant)}><motion.div className={"framer-176jtjv"} data-framer-name={"Moving Light"} layoutDependency={layoutDependency} layoutId={"An4nwZfky"} style={{background: "radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0.7) 0%, rgba(0, 0, 0, 0) 100%)"}}/><motion.div className={"framer-dwmqxl"} data-framer-name={"Container"} layoutDependency={layoutDependency} layoutId={"BSd2emUpi"} style={{backgroundColor: COj8n3hCj, borderBottomLeftRadius: radiusForCorner(tGcqj6wny, 3), borderBottomRightRadius: radiusForCorner(tGcqj6wny, 2), borderTopLeftRadius: radiusForCorner(tGcqj6wny, 0), borderTopRightRadius: radiusForCorner(tGcqj6wny, 1)}}/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-ZrXO0.framer-ob6x9e, .framer-ZrXO0 .framer-ob6x9e { display: block; }", ".framer-ZrXO0.framer-p5le3l { align-content: center; align-items: center; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 10px; height: 45px; justify-content: center; overflow: hidden; padding: 1px; position: relative; width: 182px; will-change: var(--framer-will-change-override, transform); }", ".framer-ZrXO0 .framer-176jtjv { aspect-ratio: 1 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 40px); left: -20px; overflow: visible; position: absolute; top: -20px; width: 40px; z-index: 1; }", ".framer-ZrXO0 .framer-dwmqxl { flex: 1 0 0px; height: 1px; overflow: visible; position: relative; width: 100%; z-index: 1; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-ZrXO0.framer-p5le3l { gap: 0px; } .framer-ZrXO0.framer-p5le3l > * { margin: 0px; margin-bottom: calc(10px / 2); margin-top: calc(10px / 2); } .framer-ZrXO0.framer-p5le3l > :first-child { margin-top: 0px; } .framer-ZrXO0.framer-p5le3l > :last-child { margin-bottom: 0px; } }", ".framer-ZrXO0.framer-v-mjqnap .framer-176jtjv { left: unset; right: -20px; }", ".framer-ZrXO0.framer-v-1wovle1 .framer-176jtjv { bottom: -20px; left: unset; right: -20px; top: unset; }", ".framer-ZrXO0.framer-v-4pxkwv .framer-176jtjv { bottom: -20px; top: unset; width: 50px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 45
 * @framerIntrinsicWidth 182
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"Jywg6prkS":{"layout":["fixed","fixed"]},"bjFnwewgB":{"layout":["fixed","fixed"]},"emPCwEEVo":{"layout":["fixed","fixed"]},"iF7mVgbOF":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"tGcqj6wny":"radius","COj8n3hCj":"background"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramermIwF5KtsH: React.ComponentType<Props> = withCSS(Component, css, "framer-ZrXO0") as typeof Component;
export default FramermIwF5KtsH;

FramermIwF5KtsH.displayName = "Helper/Moving BG";

FramermIwF5KtsH.defaultProps = {height: 45, width: 182};

addPropertyControls(FramermIwF5KtsH, {variant: {options: ["grTvWsSDA", "Jywg6prkS", "bjFnwewgB", "emPCwEEVo", "iF7mVgbOF"], optionTitles: ["Variant 1", "Variant 2", "Variant 3", "Variant 4", "Variant 5"], title: "Variant", type: ControlType.Enum}, tGcqj6wny: {defaultValue: 16, title: "Radius", type: ControlType.Number}, COj8n3hCj: {defaultValue: "var(--token-01526063-22e0-41e6-9449-953f948a186a, rgb(19, 19, 21)) /* {\"name\":\"Dark/08\"} */", title: "Background", type: ControlType.Color}} as any)

addFonts(FramermIwF5KtsH, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})